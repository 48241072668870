/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7lx7nmujkradfmgbeu3bruopqe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ececbxk3jjhf3nsg4q6raejmiy",
    "aws_cognito_identity_pool_id": "us-east-1:a2f3b8d7-6589-48fc-be47-266abeb084e0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oLxl87uLl",
    "aws_user_pools_web_client_id": "2k88kvhb3pr7ts5s6jj0o7b316",
    "oauth": {
        "domain": "eazy2conwebappd9db6ba2-d9db6ba2-test.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/mentor-dashboard/,https://mentortribes.com/mentor-dashboard/",
        "redirectSignOut": "http://localhost:3000/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "masterbucketnameformentortribes171923-test",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
